<template>
    <div>
      <!-- <v-container fluid class="down-top-padding"> -->
      <vue-element-loading
        :active="appLoading"
        spinner="bar-fade-scale"
        color="#1976d2"
        size="128"
        is-full-screen
      />
      <v-snackbar v-model="showsnackbar" color="black" right>
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showsnackbar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout v-if="ServerError" wrap justify-center>
        <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
          <ServerError />
        </v-flex>
      </v-layout>
      <v-layout v-else wrap justify-center>
        <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
          <v-form ref="form" lazy-validation>
            <v-layout wrap>
              <v-flex xs12 pt-2>
                <v-card>
                  <v-card-title class="elevation-1">
                    Affiliation Commission
                    <v-spacer></v-spacer>
                    <v-col cols="6" md="2" class="py-2 px-11">
                        <v-dialog
                      :retain-focus="false"
                      v-model="dialog"
                      persistent
                      max-width="600px"
                      :key="dialog"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="green" v-bind="attrs" v-on="on">
                          Add
                        </v-btn>
                      </template>
                      <v-card>
                       
                          <v-card-title>
                            <span class="headline">Add Commission <span style="font-size: 15px;">(Range should be 0-1000, 1001-2000)</span></span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="minrange"
                                    label=" Minimum Range"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="maxrange"
                                    label=" Maximum Range"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="amt"
                                    label=" Amount"
                                    required
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="dialog=false"
                            >
                              Close
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="add()"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                      </v-card>
                    </v-dialog>
                    </v-col>
                    <v-col cols="6" md="2" class="py-2 px-11">
                      <v-btn outlined color="green" to="/coinsReport">
                        <span class="item_title">View Report</span>
                      </v-btn>
                    </v-col>
                  </v-card-title>
                  <v-data-table
                    :headers="headers"
                    :items="list"
                    :search="search"
                    hide-default-footer
                    class="elevation-1"
                  >
                    <template v-slot:[`item.image`]="{ item }">
                      <img
                        v-bind:src="baseURL + item.image"
                        width="auto"
                        height="50px"
                      />
                    </template>
                    <template v-slot:[`item._id`]="{ item }">
                      <v-icon small class="mr-2" @click="editcat(item)">
                        mdi-pencil
                      </v-icon>
                    </template>
                    <template v-slot:top>
                      <v-dialog
                        v-model="editdialog"
                        :retain-focus="false"
                        max-width="600px"
                      >
                        <v-card>
                          <v-card-title>
                            <span class="headline">Edit Commission  <span style="font-size: 15px;">(Range should be 0-1000, 1001-2000)</span></span>
                          </v-card-title>
  
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="editingitem.min_commission_range"
                                    label=" Minimum Range"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="editingitem.max_commission_range"
                                    label=" Maximum Range"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="editingitem.commission_amt"
                                    label=" Amount"
                                    required
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
  
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="editdialog = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="edit()">
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </template>
                  </v-data-table>
                  <!-- <div class="pt-2 blue-grey lighten-5">
                    <v-pagination
                      :length="perPage"
                      :total-rows="totalData"
                      v-model="currentPage"
                    ></v-pagination>
                  </div> -->
                </v-card>
              </v-flex>
            </v-layout>
          </v-form>
        </v-flex>
      </v-layout>
      <!-- </v-container> -->
    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        showsnackbar: false,
        ServerError: false,
        msg: null,
        pages: 0,
        dialog: false,
        editdialog: false,
        editingitem: {},
        pageCount: 1,
        appLoading: false,
        currentPage: 1,
        perPage: 4,
        totalData: 0,
        totalRows: 0,
        dialogDelete: false,
        minrange:"",
        maxrange:"",
        amt:"",
        list: [],
        headers: [
          { text: "Minimum Commission Range", value: "min_commission_range" },
          { text: "Maximum Commission Range", value: "max_commission_range" },
          { text: "Commission Amount", value: "commission_amt" },
          { text: "Actions", value: "_id", sortable: false },
        ],
        rules: {
          required: (value) => !!value || "Required.",
          min: (v) => v.length >= 8 || "Min 8 characters",
        },
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
      getData() {
        this.appLoading = true;
        axios({
          url: "/commission/range/list",
          method: "GET",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.list = response.data.data;
            this.totalData = response.data.count;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      add() {
        var data = {};
        data["minrange"] = this.minrange;
        data["maxrange"] = this.maxrange;
        data["amt"] = this.amt;
        axios({
          url: "/commission/range/add",
          method: "POST",
          data: data,
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.editdialog = false;
  
            this.appLoading = false;
            if (response.data.status) {
              this.msg = "Added Sucessfully";
              this.showsnackbar = true;
              this.dialog=false
              this.getData();
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      editcat(item) {
        this.editingitem = item;
        this.editdialog = true;
      },
      edit() {
        var data = {};
        data["id"] = this.editingitem._id;
        data["minrange"] = this.editingitem.min_commission_range;
        data["maxrange"] = this.editingitem.max_commission_range;
        data["amt"] = this.editingitem.commission_amt;
        axios({
          url: "/commission/range/edit",
          method: "POST",
          data: data,
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.editdialog = false;
  
            this.appLoading = false;
            if (response.data.status) {
              this.msg = "Edited Sucessfully";
              this.showsnackbar = true;
              this.getData();
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
  
  <style scoped>
  .table-striped:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  </style>
  